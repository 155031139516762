import React from 'react'
import Modal from 'components/modal'
import { BEURO__Btn as Btn } from 'styleguide/packages/components'
import styles from './styles.scss'

const PromptModal = props => {
  const { body, onPromptAccepted, onPromptRejected, ...rest } = props

  return (
    <Modal augmentBaseClass="notification-prompt-modal" {...rest}>
      <h2 className="BEURO__section-header">AEDIT would like to send you desktop notifications</h2>
      <p className="BEURO__body">{body}</p>
      <div className="button-strip">
        <Btn.Ghost onClick={onPromptRejected} content="I don't want to be notified" />
        <Btn.Gold onClick={onPromptAccepted} content="Notify me" />
      </div>
      <style jsx>{styles}</style>
    </Modal>
  )
}

export default PromptModal
